<template>
  <div 
    class="main-pop"
     :class="{'show':showPopups}">
      <div
      :id="ids"
      class="box-modal"
      @click="function(){return false}">
        <div class="d-block text-center">
          <div 
            class="close-pop"
            @click="hidePopup()"
          >
            <svg width="24px" height="24px" viewBox="0 0 24 24">
              <g data-name="close">
                <rect width="24" height="24" transform="rotate(180 12 12)" opacity="0"/>
                <path d="M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z"/>
              </g>
            </svg>
          </div>
          <div v-if="ids==='popupToken' || ids==='popupReward'">
            <b-img :src="dataPopup.image" center rounded class="img-box"></b-img>
            <h3 class="title-popup">{{ dataPopup.title }}</h3>
            <p class="txt-desc">{{ dataPopup.description }}</p>
            <b-button
              class="mt-3 btn-defaultx"
              block
              @click="actionButton()"
              >{{ dataPopup.txtButton }}</b-button
            >
           </div>
           <div v-if="ids==='popupInfo'" style="position: relative;">
              <div class="inform">
                <SyaratAda/>
                <b-button
                  class="mt-3 btn-defaultx"
                  block
                  @click="hidePopup()"
                  >{{ dataPopup.txtButton }}</b-button
                >
              </div>
           </div>
           <div v-if="ids==='popupService'" style="position: relative;">
            <div class="head-service"></div>
              <div class="service">
                <div class="list-store">
                  <div v-for="lists in storeList" :key="lists.id" class="box-list">
                    <div class="head-list">
                      <div class="head-list-desc">
                        <h3>Rp.{{lists.service_charge}}</h3>
                        Untuk +{{lists.token}} token 
                        </div>
                    </div>
                    <div>{{lists.description}}</div>
                    <b-button
                      class="mt-1 btn-defaultx-list"
                      block
                      @click="actionPull(lists.channel,lists.keyword,lists.adn)"
                      >beli</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
      <div class="backdrop-pop" @click="hidePopup()"></div>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import SyaratAda from '@/components/Syarat.vue'
export default {
  components: {
    SyaratAda,
  },
  data: function() {
  return {};
},
  props: {
      dataPopup: Object,
      ids: String,
    },
  computed: {
    ...mapGetters({
      showPopups: 'getPopupShow',
      storeList: 'getStore',
      telco:'getTelco',
    }),
    ...mapState([
      'android',
    ]),
  },
  methods: {
    ...mapMutations({
      setPopups: 'SET_GAME_POPUP_SHOW',
    }),
      hidePopup() {
        this.setPopups(false)
      },
      actionButton() {
          this.setPopups(false)
          this.$emit('callFunc')
      },
      actionPull(channel,keyword,adn){
        this.setPopups(false)
        if(this.android && window.androidExt){
          if(channel==='SMS'){
            window.androidExt.postMessage("{'methode':'sms','var_a':'"+adn+"','var_b':'"+keyword+"'}")
          }else if(channel==='UMB'){
            // keyword = keyword.slice(0, keyword.length - 1), keyword += "%23";
            window.androidExt.postMessage("{'methode':'umb','var_a':'"+keyword+"','var_b':'null'}")
          }else if(channel==='WAP'){
            window.androidExt.postMessage("{'methode':'wap','var_a':'http://117.54.3.23:1481/"+this.telco+"?aid=1&adn="+adn+"&keyword="+keyword+"','var_b':'null'}")
          }else if(channel==='GPLAY'){
            window.androidExt.postMessage("{'methode':'gplay','var_a':'"+keyword+"','var_b':'null'}")
          }else{
            window.androidExt.postMessage("{'methode':'msg','var_a':'SERVICE BELUM TERSEDIA','var_b':'null'}")
          }
        }else{
          if(channel==='SMS'){
            location.href = 'sms:' + adn + '?body=' + keyword;
          }else if(channel==='UMB'){
            keyword = keyword.slice(0, keyword.length - 1);
            keyword += "%23";
            location.href = 'tel:' + keyword;
          }else if(channel==='WAP'){
            window.open("http://117.54.3.23:1481/"+this.telco+"?aid=1&adn="+adn+"&keyword="+keyword+"","_blank");
          }
        }
      },
      callDataService(){
        console.log(this.storeList)
      },
    // showPopupHadiah(point) {
    //   const index = this.reward.findIndex(
    //     item => item.point === point
    //   );
    //   const reward = this.reward[index];
    //   this.dataPopup.image = reward.image;
    //   this.dataPopup.title = reward.title;
    //   this.dataPopup.description = reward.description;
    //   this.dataPopup.txtButton = "Ambil";
    //   this.$bvModal.show("popupModal");
    // },
    // showPopupValidateToken() {
    //   const token = this.token;
    //   this.dataPopup.image = token.image;
    //   this.dataPopup.title = token.title;
    //   this.dataPopup.description = token.description;
    //   this.dataPopup.txtButton = "Tambah Token";
    //   console.log('muncul', this.point);
    //   this.$bvModal.show("popupModal");
    //   // this.$refs['popupModal'].show()
    // },
    // setPopup() {
    //     if (this.totalPoint !== null) {
    //       if (this.totalPoint === '0') {
    //           this.showPopupValidateToken()
    //       } else {
    //           this.showPopupHadiah(this.totalPoint)
    //       }
    //   } else {
    //       this.$bvModal.hide("popupModal")
    //   }
      
    // },
  }
};
</script>
<style scoped>
/* /deep/ .wd-custom {
    width: 230px !important;
}
/deep/ .title-popup {
    font-size: 1.1rem;
}
/deep/ .txt-desc {
    font-size: .8rem;
} */
.backdrop-pop{
  width:100vw;
  height:100vh;
  background:#000000d9;
  position: relative;
  z-index:1001;
}
.main-pop{
  display:none;
}
.main-pop.show{
  display: block;
}
.box-modal{
  color: #fff !important;
  background: #00000087;
  border: 1px solid #ffffff2b;
  width: 50vw;
  height: calc(100vh - 3rem);
  min-width: 15rem !important;
  max-width: 30rem;
  margin: auto;
  position: absolute;
  z-index: 1002;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 1rem;
  border-radius: 9px;
  box-shadow: #000000c2 1px 1px 10px;
  text-transform: uppercase;
}

.box-modal h3.title-popup{
  font-size: 5vh;
  margin-top: 0.5rem;
  font-weight: 600;
}
.img-box{
  height:32vh;
}
.txt-desc{
  font-size:4vh;
}
.close-pop{
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    width: 28px;
    height: 27px;
    fill: #600000;
    background: #fb6b06fa;
    border-radius: 13px;
    z-index: 24;
}
.btn-defaultx, .btn-defaultx-list{
  background: #fb6b06fa;
    border: #ff9e0a 3px solid;
    border-left-color: #ff7200;
    border-bottom-color: #ff7200;
    margin: auto;
    text-transform: uppercase;
    position: relative;
    overflow: hidden;
}
.btn-defaultx::before, .btn-defaultx-list::before{
    content: "";
    height: 20px;
    background: #ffff192e;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
}
.btn-defaultx-list{
  box-shadow: 0px 0px 6px #00000073;
}
.btn-defaultx{
  width: 68% !important;
  position: absolute;
  bottom: 1rem;
  left: 0;
  right: 0;
}
.service, .inform{
  margin-top: 1.5rem;
  /* padding: 0.5rem; */
  background: #000000a3;
  border-radius: 7px;
  height: calc( 100vh - 7.5rem);
  overflow: scroll;
}
.service{
  margin-top: 7.5rem;
  height: calc( 100vh - 13.5rem);
}
.head-service{
  background-image: url("../assets/knopi.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  width: 100%;
  min-width: 11rem !important;
  max-width: 30rem;
  height: 12rem;
  top: -130px;
  z-index: 20;
  pointer-events: none;
}
.inform{
  height: calc( 100vh - 9.5rem);
}
.inform .btn-defaultx{
    width: 25% !important;
    bottom: -3.2rem;
    right: 1rem;
    left: auto;
}
.box-list{
    padding: 0.5rem;
    color: #767676;
    font-weight: 700;
    border-radius: 7px;
    margin: 0.5rem;
    background-color: #282828;
    position: relative;
    padding-bottom: 1rem;
    text-transform:none;
}
.head-list{
  background: rgb(37,166,255);
   background-image: url("../assets/coins.png");
   background-repeat: no-repeat;
   background-size: cover;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    padding-bottom: 2rem;
    color: #fff;
    position: relative;
    padding-top: 2rem;
    margin: -0.5rem;
    margin-bottom: 1rem;
}
.head-list-desc{
    display: inline-block;
    background: #000000a8;
    padding: 0.5rem 1rem;
    font-weight: 100;
    font-size: 15px;
    border-radius: 9px;
}
#popupInfo{
  width: 90vw;
  max-width: 41rem;
}
@keyframes modalshow {
  0%   { top:400px;}
  100% {top:0px;}
}
.main-pop.show .box-modal{
  animation-name: modalshow;
  animation-duration: 1s;
}
</style>
